<template>
  <div class="">
    <div id="preloader" v-if="!ready || loading">
      <div data-loader="circle-side"></div>
    </div>
    <!-- /Preload -->

    <div id="loader_form">
      <div data-loader="circle-side-2"></div>
    </div>
    <!-- /loader_form -->

    <div class="container-fluid full-height">
      <div class="row row-height">
        <div class="col-lg-6 content-left no-gutters">
          <div class="content-left-wrapper">
            <!-- <a href="index.html" id="logo"
              ><img src="img/logo.png" alt="" width="49" height="35"
            /></a> -->
            <div id="social">
              <ul>
                <li>
                  <a href="#0"><i class="icon-google"></i></a>
                </li>
                <li>
                  <a href="#0"><i class="icon-linkedin"></i></a>
                </li>
              </ul>
            </div>
            <!-- /social -->
            <div>
              <figure>
                <img src="img/acumen-logo.png" alt="" class="img-fluid" />
              </figure>
              <h2>IOSS Registration</h2>
              <p>Coming soon!</p>
            </div>
            <div class="copy">© 2021 Acumen International Business Consultancy SL</div>
          </div>
          <!-- /content-left-wrapper -->
        </div>
        <!-- /content-left -->

        <div class="col-lg-6 content-right no-gutters" id="start">
          <div id="wizard_container">
            <div id="top-wizard">
              <div id="progressbar"></div>
            </div>
            <!-- /top-wizard -->
            <form id="wrapped" method="POST" enctype="multipart/form-data">
              <input id="website" name="website" type="text" value="" />
              <!-- Leave for security protection, read docs for details -->
              <div id="">
                <div>
                  <div class="form-group">
                    <h6 class="mt-5">
                      Leave your email and we will contact you to kick off with the IOSS registration process.
                    </h6>

                    <div class="form-group">
                      <input
                        v-model="myEmail"
                        type="text"
                        name="firstname"
                        class="form-control required"
                        placeholder="Email"
                      />
                    </div>

                    <p :class="{
                      'text-success': !error,
                      'text-danger': error
                    }"> {{ responseMessage }}</p>
                  </div>
                </div>
              </div>
              <!-- /middle-wizard -->
              <div id="bottom-wizard" class="pb-3">
                <a-space :space="8">
                  <button :disabled="!emailFilled" @click.prevent="submit" type="submit" name="process" class="submit">
                    Contact me
                  </button>
                </a-space>
              </div>
              <!-- /bottom-wizard -->
            </form>
          </div>
          <!-- /Wizard container -->
        </div>
        <!-- /content-right-->
      </div>
      <!-- /row-->
    </div>
    <!-- /container-fluid -->

    <div class="cd-overlay-nav">
      <span></span>
    </div>
    <!-- /cd-overlay-nav -->

    <div class="cd-overlay-content">
      <span></span>
    </div>
    <!-- /cd-overlay-content -->
    <!-- /menu button -->

    <!-- Modal terms -->
    <div
      class="modal fade"
      id="terms-txt"
      tabindex="-1"
      role="dialog"
      aria-labelledby="termsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="termsLabel">Terms and conditions</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in
              <strong>nec quod novum accumsan</strong>, mei ludus tamquam
              dolores id. No sit debitis meliore postulant, per ex prompta
              alterum sanctus, pro ne quod dicunt sensibus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
              novum accumsan, mei ludus tamquam dolores id. No sit debitis
              meliore postulant, per ex prompta alterum sanctus, pro ne quod
              dicunt sensibus. Lorem ipsum dolor sit amet,
              <strong>in porro albucius qui</strong>, in nec quod novum
              accumsan, mei ludus tamquam dolores id. No sit debitis meliore
              postulant, per ex prompta alterum sanctus, pro ne quod dicunt
              sensibus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
              novum accumsan, mei ludus tamquam dolores id. No sit debitis
              meliore postulant, per ex prompta alterum sanctus, pro ne quod
              dicunt sensibus.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn_1" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import { Space } from "ant-design-vue";
Vue.use(Space);

export default {
  name: "Announce",
  components: {},
  props: {},
  data: function () {
    return {
      ready: false,
      loading: false,
      error: false,
      responseMessage: "",
      myEmail: "",
    };
  },
  methods: {
    submit() {
      this.loading = true;
      axios
        .post("https://u8d2if44xb.execute-api.eu-central-1.amazonaws.com/dev/static-site-mailer", {
          email: this.myEmail,
        })
        .then(() => {
          this.error = false;
          this.responseMessage = "Thank you for filling out contact email. We will come back to you soon."
          this.myEmail = ""
        })
        .catch((error) => {
          console.error(error);
          this.error = true;
          this.responseMessage = "Something went wrong. Please try again later."
        })
        .finally(() => {
          this.loading = false;
        })
    },
  },
  computed: {
    emailFilled(){
      return this.myEmail.length !== 0
    }
  },
  mounted() {
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-gutters {
  padding-right: 0;
  padding-left: 0;
}
</style>
