import { CountryService } from '@/services/api';

export const namespaced = true

export const state = {
  countries: []
}

export const mutations = {
  SET_COUNTRIES(state, data) {
    state.countries = data
  },
}

export const actions = {
  fetchCountries({ commit }) {
    CountryService.getCountries()
    .then(response => {
      commit("SET_COUNTRIES", response.data.data.countries)
    })

  },
}
export const getters = {
  countries: state => {
      return state.countries;
  }
}