import Vue from 'vue'
import VueRouter from 'vue-router'
import Announce from '../views/Announce.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/announce',
    name: 'Announce',
    component: Announce
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/ioss/decrypt',
    name: 'Decrypt IOSS number',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ '../views/Decrypt.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
