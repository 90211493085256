import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import JwtService from "./jwt.service";
// import CsrfService from "./csrf.service";
import { API_URL } from "./config";

const webApiPrefix = "wapi";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setCsrfHeader() {
    // Vue.axios.defaults.headers.common[
    //   "X-CSRF-TOKEN"
    // ] = `${CsrfService.getToken()}`;
  },

  setHeader() {
    // Vue.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[VAT4U] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[VAT4U] ApiService ${error}`);
    });
  },

  getOld(endpoint) {
    return Vue.axios.get(`${endpoint}`).catch((error) => {
      throw new Error(`[VAT4U] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource, data = {}) {
    return Vue.axios.delete(resource, data).catch((error) => {
      throw new Error(`[VAT4U] ApiService ${error}`);
    });
  },
};

ApiService.init();

export default ApiService;

export const AccountService = {
  autosave(representativeDomainKey, token, formData) {
    return ApiService.post(
      `${webApiPrefix}/ioss/account/autosave/${representativeDomainKey}/${token}`,
      formData
    );
  },
  create(token, formData) {
    return ApiService.post(`${webApiPrefix}/ioss/account/${token}`, formData);
  },
  getSavedForm(token) {
    return ApiService.get(`${webApiPrefix}/ioss/account/autosave/${token}`);
  },
};

export const IossNumberService = {
  decryptData(username, encryptedNumber, userKey) {
    return ApiService.post(`${webApiPrefix}/ioss/number/decrypt`, {
      username,
      encryptedNumber,
      userKey,
    });
  },
};

export const CountryService = {
  getCountries() {
    return ApiService.get(`${webApiPrefix}/country/public`);
  },
};
