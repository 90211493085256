import Vue from 'vue'
import Vuex from 'vuex'
import * as form from '@/store/modules/form'
import * as system from '@/store/modules/system'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    form,
    system,
  },
  state: {
  }
})
