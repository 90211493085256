import { AccountService } from '@/services/api';
import Vue from 'vue';

export const namespaced = true

export const state = {
  // tenant specific company key
  representativeDomainKey: 'e1b4de70-0f0f-4f0f-b330-672b3f59ccca',
  token: null,
  form: {
    step: 0,

    firstName: '',
    lastName: '',
    userEmail: '',
    userPhone: '',
    userJobTitle: '',
    serviceReferenceCode: '',

    companyLegalName: '',
    companyCorCode: null,
    incorporationType: "",
    incorporationRegistryType: "",
    incorporationRegistryName: "",
    chamberOfCommerceNumber: "",
    chamberOfCommerceDocument: null,
    
    companyAddressStreet: '',
    companyAddressHouseNumber: '',
    companyAddressOther: '',
    companyAddressPostalCode: '',
    companyAddressCity: '',
    companyAddressCountryCode: null,
    differentCorrespondenceAddress: null,

    correspondenceAddressStreet: '',
    correspondenceAddressHouseNumber: '',
    correspondenceAddressOther: '',
    correspondenceAddressPostalCode: '',
    correspondenceAddressCity: '',
    correspondenceAddressCountryCode: null,

    companyWebsite: '',
    companyEmail: '',
    companySalesActivities: '',

    hasMultipleEshopWebsites: null,
    eshopWebsites: [],
    hasMultipleTradingNames: null,
    tradingNames: [],
    nationalTaxReferenceNumber: '',
    nationalTaxReferenceNumberCountryCode: null,
    nationalTaxReferenceDocument: null,
    hasOtherEuEstablishments: null,
    otherEuEstablishments: [],
    hasIndirectSales: null,
    hasDirectSalesViaOwnShop: false,
    hasIndirectSalesViaOtherMarketplaces: false,
    indirectSalesInterfaces: [],
    isIossRegistered: null,
    iossRegistrations: [],
    wasIossRegistered: null,
    pastIossRegistrations: [],
    dateOfFirstSupply: "",
    placesOfDispatch: "",
    placesOfDestination: "",
    logisticsProviders: "",
    customsBroker: "",
    consigmentsPerMonth: "",
    goodsSoldPerMonth: "",
    countriesOfEntry: "",
    bankAccountHolderName: "",
    bankName: "",
    bankCountryCode: null,
    bankAccountNumber: "",
    bankIban: "",
    bankBicOrSwiftCode: "",
    representativeSalutation: "",
    representativeFirstName: "",
    representativeLastName: "",
    representativeJobTitle: "",
    isPassport: true,
    legalRepresentativeIdFrontPage: null,
    legalRepresentativeIdBackPage: null,
    userConfirmation: false,
    userAcknowledgement: false,
  }
}


export const mutations = {
  SET_FIRST_NAME(state, name) {
    state.firstName = name
  },
  UPDATE_FORM_PROP(state, { propName, value }) {
    // state.form = Object.assign(state.form, payload)
    state.form[propName] = value
  },
  SET_FORM_PROPERTY: (state, payload) => {
    console.log("current", state);
    console.log("setting", payload);
    state.form = Object.assign(state.form, payload.form);
  },
  SET_TOKEN(state, token) {
    console.log("SET TOKEN", token)
    state.token = token
  },
  SET_FORM_DATA(state, data) {
    console.log("SET_FORM_DATA", data)
    state.form = Object.assign(state.form, data)
  },
  SET_FORM_MULTIELEMENT_PROP(state, { prop, index, data }) {
    console.log(`Setting element for '${prop}' at ${index}`, data)
    Vue.set(state.form[prop], index, data)
  },
  ADD_FORM_MULTIELEMENT(state, { prop, data }) {
    console.log(`Adding element '${prop}'`, data)
    state.form[prop].push(data)
  },
  REMOVE_FORM_MULTIELEMENT(state, { prop, index }) {
    console.log(`Removing element '${prop}' at ${index}`)
    console.log(`Element`, state.form[prop])
    state.form[prop].splice(index, 1);
  },
  SET_FORM_MULTIELEMENT_OBJECT_PROP(state, { prop, index, objectProp, data }) {
    console.log(`Setting element for '${prop}' at ${index} for ${objectProp}`, data)
    Vue.set(state.form[prop][index], objectProp, data)
  },

}

export const actions = {
  load(context, token) {
    context.commit('SET_TOKEN', token)

    // https://app.vat4u.local/wapi/ioss/account/b7f8b8f4-60f4-476c-aaff-f546cd0afcf6
    return new Promise((resolve, reject) => {
      AccountService.getSavedForm(context.state.token)
        .then(response => {
          console.log(response)
          const data = response.data
          if (data.status === 'success') {
            context.commit("SET_FORM_DATA", data.data.form)
            resolve()
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setFormArrayObjectProperty(context, { prop, index, objectProp, data }) {
    context.commit("SET_FORM_MULTIELEMENT_OBJECT_PROP", { prop, index, objectProp, data })
  },
  setFormArrayProperty(context, { prop, index, data }) {
    context.commit("SET_FORM_MULTIELEMENT_PROP", { prop, index, data })
  },
  addFormArrayProperty(context, { prop, data }) {
    context.commit("ADD_FORM_MULTIELEMENT", { prop, data })
  },
  removeFormArrayProperty(context, { prop, index }) {
    context.commit("REMOVE_FORM_MULTIELEMENT", { prop, index })
  },
  autosave(context) {
    return new Promise((resolve, reject) => {
      AccountService.autosave(context.state.representativeDomainKey, context.state.token, context.state.form)
        .then(response => {
          console.log(response)
          const data = response.data
          if (data.status === 'success') {
            context.commit("SET_TOKEN", data.data.token)
            resolve(data.data)
          }
        })
        .catch(error => {
          reject(error)
        })

    })
  },
  set: (context, payload) => {
    context.commit('SET_FORM_PROPERTY', payload);
  },
}

export const getters = {
  getFormData: state => {
    return state;
  },
  token: state => {
    return state.token
  },
  userEmail: state => {
    return state.form.userEmail
  },
  userPhone: state => {
    return state.form.userPhone
  },
  userConfirmation: state => {
    return state.form.userConfirmation
  },
  userAcknowledgement: state => {
    return state.form.userAcknowledgement
  }
}
